import { motion } from "framer-motion";
import "../transition/transition.css";

const calculateRandomBlockDelay = (rowIndex, totalRows) => {
    const blockDelay = Math.random() * 0.5;
    const rowDelay = (totalRows - rowIndex - 1) * 0.05;
    return blockDelay + rowDelay;
};

const Transition = (Page) => {
    return () => (
        <>
            <Page />
            <div className="blocks-container transition-in">
                {Array.from({ length: 4 }).map((_, rowIndex) => (
                    <div className="row" key={rowIndex}>
                        {Array.from({ length: 4 }).map((_, blockIndex) => (
                            <motion.div
                                key={blockIndex}
                                className="block"
                                initial={{ opacity: 1 }}
                                animate={{ opacity: 0 }}
                                exit={{ opacity: 0 }}
                                transition={{
                                    duration: 1,
                                    ease: [0.44, 2, 0.72, 1],
                                    delay: calculateRandomBlockDelay(rowIndex, 20),
                                }}
                                style={{ transformOrigin: 'top' }} // Hier transform-origin hinzufügen
                            ></motion.div>
                        ))}
                    </div>
                ))}
            </div>
            <div className="blocks-container transition-out">
                {Array.from({ length: 4 }).map((_, rowIndex) => (
                    <div className="row" key={rowIndex}>
                        {Array.from({ length: 4 }).map((_, blockIndex) => (
                            <motion.div
                                key={blockIndex}
                                className="block"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 0 }}
                                exit={{ opacity: 1 }}
                                transition={{
                                    duration: 1,
                                    ease: [0.44, 2, 0.72, 1],
                                    delay: calculateRandomBlockDelay(rowIndex, 10),
                                }}
                                style={{ transformOrigin: 'center' }} // Hier transform-origin hinzufügen
                            ></motion.div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    );
};

export default Transition;
