import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SplitType from "split-type";

gsap.registerPlugin(ScrollTrigger);

export function animateText() {
    const splitElements = document.querySelectorAll(".text-split");

    splitElements.forEach((el) => {
        const splitText = new SplitType(el, {
            types: "words, chars, lines",
        });

        gsap.from(splitText.words, {
            opacity: 0,
            y: 50,
            duration: 1,
            stagger: 0.05,
            ease: "power4.out",
            scrollTrigger: {
                trigger: el,
                start: "top 90%",
            },
        });
    });

    // const lines = document.querySelectorAll(".line-is-gray");

    // gsap.from(lines, {
    //     width: 0,
    //     duration: 1,
    //     ease: "power4.out",
    //     scrollTrigger: {
    //         trigger: lines,
    //         start: "top 90%",
    //         markers: true,
    //     },
    // });
}
