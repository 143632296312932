import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../sanityClient';
import './gallery-detail.css';
import Transition from '../transition/transition';
import { animateText } from "../../components/textAnimation/textAnimation";

function GalleryDetail() {
    const { slug } = useParams();
    const [post, setPost] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const [fade, setFade] = useState(false);

    useEffect(() => {
        client.fetch(`
            *[_type == "post" && slug.current == $slug][0]{
                title,
                slug,
                location,
                description,
                credit,
                specs,
                tag,
                publishedAt,
                coverImage{asset->{url}},
                images[]{asset->{url}}
            }
        `, { slug })
            .then((data) => {
                setPost(data);
                setCurrentImage(data.coverImage?.asset?.url);
                setTimeout(() => {
                    animateText();
                }, 200);

                // console.log('Post Data:', data);
                // console.log('Other images:', data.images.map(image => image.asset.url));
            })
            .catch(console.error);
    }, [slug]);

    const handleImageClick = (url) => {
        setFade(true);
        setTimeout(() => {
            setCurrentImage(url);
            setFade(false);
        }, 300);
        // console.log('Current image URL:', url);
    };

    if (!post) return

    return (
        <div className="gallery-detail-container">
            <div className="gallery-detail-main">
                <div className="main-image">
                    <img
                        src={currentImage}
                        alt={post.title}
                        className={fade ? 'fade-out' : 'fade-in'}
                    />
                </div>
                <div className="thumbnail-images" data-lenis-prevent>
                    {post.coverImage && (
                        <img
                            src={post.coverImage.asset.url}
                            alt="Cover"
                            onClick={() => handleImageClick(post.coverImage.asset.url)}
                            className="cover-image"
                        />
                    )}
                    {post.images?.map((image, index) => (
                        <img
                            key={index}
                            src={image.asset.url}
                            alt={`Thumbnail ${index}`}
                            className='thumbnail-img'
                            onClick={() => handleImageClick(image.asset.url)}
                        />
                    ))}
                </div>
            </div>
            <div className="gallery-detail-header">
                <div className="album-details text-small text-is-gray">
                    <div style={
                        {
                            textTransform: 'capitalize'
                        }
                    }>{post.tag}</div>
                    <div>&#x2022;</div>
                    <div>{post.location}</div>
                </div>
                <div className='title-one text-split'>{post.title}</div>
                {/* <span>{new Date(post.publishedAt).toLocaleDateString()}</span> */}
            </div>
            <div className='gallery-detail-credit'>
                <div className='title-two text-split'>Credit</div>
                {post.credit && post.credit.map((credit, index) => (
                    <div key={index}>
                        <div className='line-space'>
                            <div className='text-bigger text-is-gray'>{credit.role}</div>
                            <div className='text-bigger text-is-gray'>{credit.person}</div>
                        </div>
                        <div className="line-is-gray"></div>
                    </div>
                ))}
            </div>
            <div className='gallery-detail-specs'>
                <div className='title-two text-split'>Specs</div>
                {post.specs && post.specs.map((spec, index) => (
                    <div key={index}>
                        <div className='line-space'>
                            <div className='text-bigger text-is-gray' style={
                                {
                                    textTransform: 'capitalize'
                                }
                            }>{spec.type}</div>
                            <div className='text-bigger text-is-gray' style={
                                {
                                    textTransform: 'capitalize'
                                }
                            }>{spec.description}</div>
                        </div>
                        <div className="line-is-gray"></div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Transition(GalleryDetail);
