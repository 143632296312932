import './home.css';
import React, { useEffect, useLayoutEffect, useState } from "react"; // useEffect hier importieren
import { animateText } from "../../components/textAnimation/textAnimation";
import Transition from "../../components/transition/transition";
import client from '../../sanityClient';

const Home = () => {
  const [post, setPost] = useState(null);

  useLayoutEffect(() => {
    if (post) {
      setTimeout(() => {
        animateText();
      }, 600);
    }
  }, [post]);

  useEffect(() => {
    client.fetch(`
      *[_type == "post"] | order(publishedAt)[0] {
        title,
        slug,
        coverImage{asset->{url}},
        location,
        publishedAt,
        images{asset->{url}},
        tag
      }
    `).then(data => {
      setPost(data);
    }).catch(err => {
      console.error("Fehler beim Abrufen des Posts:", err);
    });
  }, []);

  if (!post) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <div className="container">
        <div className="content">
          <div className="content-title">
            <div className='title-one text-split margin-bl'>
              Known for dynamic sports photography and engaging storytelling.
            </div>
            <div className='text-regular text-is-gray margin-tb'>Latest Work</div>
          </div>

          <div className="home-img-main">
            <img
              src={post.coverImage.asset.url}
              alt={post.title}
              className="post-image"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transition(Home);
