import React, { useEffect, useRef, useState } from 'react';
import './footer.css';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Link, useLocation } from 'react-router-dom';
import { div } from 'framer-motion/client';

gsap.registerPlugin(ScrollTrigger);

function Footer() {
    return (
        <div className='footer'>
            <div className="line-is-gray"></div>
            <div className='footer-content'>
                <div className='text-is-gray text-regular'>© 2024 Luka Kolanovic </div>
                <div className='footer-credit'>
                    <span className='text-regular text-is-gray'>Web design by</span>
                    <div className="text-small is-link">
                        <div className="underline-wrapper">
                            <p className='text-is-white text-regular no-margin'>Yevuno Visuals</p>
                            <div className="bg-is-grey underline-effect-white"></div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default Footer;
