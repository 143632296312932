import React, { useState } from 'react';
import './work.css';
import ImgNavBar from '../../components/img-nav/img-nav';
import ImgGrid from '../../components/img-grid/img-grid';
import Transition from '../../components/transition/transition';

function Work() {
  const [activeFilter, setActiveFilter] = useState('all');

  return (
    <div className="App">
      <ImgNavBar onFilterChange={setActiveFilter} />
      <div className="container">
        <div className="content">
          <div className="content-title">
            <div className='title-one'>
              Work
            </div>
          </div>
        </div>
      </div>
      <ImgGrid activeFilter={activeFilter} />
    </div>
  );
}

export default Transition(Work);
