import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Lenis from "lenis";
import Menu from "./components/menu/menu";
import Home from "./pages/Home/home";
import Work from "./pages/Work/work";
import About from "./pages/About/about";
import Contact from "./pages/Contact/contact";
import LoadingScreen from "./components/loading-screen/loading-screen";
import Cursor from "./components/cursor/cursor";
import GalleryDetail from "./components/gallery-detail/gallery-detail";
import Footer from "./components/footer/footer";

import { AnimatePresence } from "framer-motion";

function App() {
    const location = useLocation();

    useEffect(() => {
        const lenis = new Lenis();
        function raf(time) {
            lenis.raf(time);
            requestAnimationFrame(raf);
        }
        requestAnimationFrame(raf);

        return () => lenis.destroy(); // Lenis sauber entfernen
    }, []);

    return (
        <>
            <Cursor />
            <Menu />
            <AnimatePresence mode="wait">
                {/* <LoadingScreen /> */}
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Home />} />
                    <Route path="/work" element={<Work />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/gallery/:slug" element={<GalleryDetail />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </AnimatePresence>
            <Footer />
        </>
    );
}

export default App;
