import './contact.css';
import React, { useEffect, useLayoutEffect, useState } from "react";
import { animateText } from "../../components/textAnimation/textAnimation";
import Transition from "../../components/transition/transition";
import client from '../../sanityClient';

const Contact = () => {
  return (
    <div className="container">
      <div className="title-one">Let's start a Project together</div>
      <div className='contact-container'>
        <div className='contact-field'>

          <div className='contact-field'>
            <div className="line-is-gray"></div>
            <div className='contact-inner'>
              <div className='text-is-gray title-three'>
                01
              </div>
              <div>
                <div className='title-three'>What's your Name?  <span className='color-red'>*</span></div>
                <input type='text' placeholder='John Doe' />
              </div>
            </div>
          </div>

          <div className='contact-field'>
            <div className="line-is-gray"></div>
            <div className='contact-inner'>
              <div className='text-is-gray title-three'>
                02
              </div>
              <div>
                <div className='title-three'>What's your email? <span className='color-red'>*</span></div>
                <input type='text' placeholder='john@doe.com' />
              </div>
            </div>
          </div>

          <div className='contact-field'>
            <div className="line-is-gray"></div>
            <div className='contact-inner'>
              <div className='text-is-gray title-three'>
                03
              </div>
              <div>
                <div className='title-three'>What's your organizations name? </div>
                <input type='text' placeholder='John Media' />
              </div>
            </div>
          </div>

          <div className='contact-field'>
            <div className="line-is-gray"></div>
            <div className='contact-inner'>
              <div className='text-is-gray title-three'>
                03
              </div>
              <div>
                <div className='title-three'>What kind of services are you looking for? </div>
                <input type='text' placeholder='Photography for an event' />
              </div>
            </div>
          </div>

          



        </div>
      </div>
    </div>
  );
}

export default Transition(Contact);
