import React, { useEffect, useState, useRef } from 'react';
import client from '../../sanityClient';
import { animateText } from "../../components/textAnimation/textAnimation";
import './about.css';
import Transition from '../../components/transition/transition';

function About() {
  const [featuredPosts, setFeaturedPosts] = useState([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    client.fetch(
      `*[_type == "aboutFeatured"]{
          featuredByName,
          featuredByDate,
          featuredByLink
        }`
    )
      .then((data) => setFeaturedPosts(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (featuredPosts.length > 0) {
      setTimeout(() => {
        animateText();
      }, 20);
    }
  }, [featuredPosts]);

  const firstPosts = featuredPosts.slice(0, 5);
  const remainingPosts = featuredPosts.slice(5);

  return (
    <div className="About">
      <div className="about-cover-img-container">
        <img src="/main.webp" alt="Main Cover" />
        <div className="title-one about-cover-title text-split">Luka Kolanovic</div>
      </div>
      <div className="about-content">
        <div className="about-section">
          <div className='about-section-title title-two big-margin text-split'>Featuring my Work</div>
          <div className='about-section-feat'>
            {firstPosts.map((post, index) => (
              <React.Fragment key={index}>
                <div className="about-section-feat-content">
                  <a
                    href={post.featuredByLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="feat-name text-regular text-is-gray no-margin text-split"
                  >
                    {post.featuredByName}
                  </a>
                  <div className="feat-date text-regular text-is-gray no-margin">
                    {new Date(post.featuredByDate).getFullYear()}
                  </div>
                </div>
                <div className="line-is-gray"></div>
              </React.Fragment>
            ))}
          </div>
          <div
            className='about-section-feat additional-posts'
            style={{
              height: showAll ? '1500px' : '0px',
              overflow: 'hidden',
              transition: 'all .5s ease-in-out'
            }}
          >
            {remainingPosts.map((post, index) => (
              <React.Fragment key={index}>
                <div className="about-section-feat-content">
                  <a
                    href={post.featuredByLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="feat-name text-regular text-is-gray no-margin text-split"
                  >
                    {post.featuredByName}
                  </a>
                  <div className="feat-date text-regular text-is-gray no-margin">
                    {new Date(post.featuredByDate).getFullYear()}
                  </div>
                </div>
                <div className="line-is-gray"></div>
              </React.Fragment>
            ))}
          </div>
          {featuredPosts.length > 5 && (
            <div
              className="see-all-button text-regular text-is-gray"
              onClick={() => setShowAll(!showAll)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="rgb(128, 129, 130)"
                stroke="#141414"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-down-circle"
                style={{ transform: showAll ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }}
              >
                <circle cx="12" cy="12" r="10"></circle>
                <polyline points="8 12 12 16 16 12"></polyline>
                <line x1="12" y1="8" x2="12" y2="16"></line>
              </svg>
              {showAll ? "Show less" : "Show more"}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Transition(About);
